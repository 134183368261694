import { Injectable } from '@angular/core';
import { DadosLinkSeo } from '../interfaces/geral';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(
    private readonly title: Title,
    private readonly meta: Meta,
  ) { }

  initDefaultMetaTags() {
    this.title.setTitle('Topvix App');

    this.meta.addTags([
      { name: 'description', content: 'Sistema de gestão de fidelidade' },
      { name: 'keywords', content: 'Fidelidade, cashback, pontos, vendas' },
      { name: 'author', content: 'AJSystems' },
      { name: 'robots', content: 'index, follow' },
    ]);
  }

  setMetaData(dados?: DadosLinkSeo) {
    console.log('seo service - dados', dados);

    const title = dados && dados.title ? dados.title : 'Topvix';
    const description = dados && dados.description ? dados.description : 'Topvix';
    const image = dados && dados.image_url ? dados.image_url : '';
    const image_width = dados && dados.image_width ? dados.image_width : 1200;
    const keywords = dados && dados.description ? dados.description : 'Detalhes...';

    const tags = [
      { name: 'description', content: description },
      { name: 'keywords', content: keywords },

      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:image', content: image },
      { name: 'twitter:title', content: title },
      { name: 'twitter:description', content: description },

      { name: 'mobile-web-app-capable', content: 'yes' },
      { name: 'apple-mobile-web-app-status-bar-style', content: 'black' },
      { name: 'apple-mobile-web-app-title', content: title },

      { name: 'og:title', content: title },
      { name: 'og:description', content: description },
      { name: 'og:image', content: image },
      { name: 'og:image:width', content: image_width.toString() },
      { name: 'og:type', content: 'website' },
      { name: 'og:locale', content: 'pt_BR' },
      { name: 'og:site_name', content: title },
    ];

    console.log('seo service - tags', tags);

    tags.forEach(tag => this.meta.updateTag(tag));
  }

  setTitle(dados?: DadosLinkSeo) {
    if(dados && dados.title)
    this.title.setTitle(dados.title);
  }
}
