import { CommonModule, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Component, inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { Router, RouterLink } from '@angular/router';

import { IdleService } from '../../services/idle.service';
import { idleStatusType } from '../../enums/Enums';
import { PageAliasPipe } from '../../pipes/page-alias.pipe';
import { AuthorizationService } from '../../services/authorization.service';
import { UserService } from '../../services/user.service';
import { AuthenticationService } from '../../services/authentication.service';
import { environment } from '../../../environments/environment';
import { SpecialDataService } from '../../services/special-data.service';
import { AjusteLabelMenuPipe } from "../../pipes/ajuste-label-menu.pipe";

@Component({
  selector: 'app-breadcrumb',
  standalone: true,
  templateUrl: './breadcrumb.component.html',
  styleUrl: './breadcrumb.component.css',
  imports: [RouterLink, CommonModule, PageAliasPipe, AjusteLabelMenuPipe],
})
export class BreadcrumbComponent implements OnInit {
  @Input() isHidden = false;
  @Input() currentUrl = '';

  pages: string[] = [];

  routes = inject(Router);

  idleStatus?: idleStatusType;
  idleBGColor = 'bg-white';

  platformId = inject(PLATFORM_ID);
  get isBrowser() {
    return isPlatformBrowser(this.platformId);
  }
  get isServer() {
    return isPlatformServer(this.platformId);
  }
  
  constructor(
    private idleService: IdleService,
    private authorizationService: AuthorizationService,
    private userService: UserService,
    private authService: AuthenticationService,
    private specialDataService: SpecialDataService,
  ) {
    //monitorar as alterações de rota
    // initFlowbite();

    this.routes.events.subscribe(async (value) => {
      // console.log('BREADCRUMB - tipo:', value.type, value);

      if (value.type === 1) {

        // if(this.isServer) return;

        const pages = decodeURI(value.url);
        
        // debugger;

        // console.log('BREADCRUMB - url:', pages);

        const _loggedIn = this.authService.isAuthenticated;
        
        if (_loggedIn) {

          const _list = this.authorizationService.currentAppTransactionsList;

          if (
            !_list ||
            !_list.length
          ) {

            
            // const _list = await this.authorizationService.getTransacoesList();
            
            // if (_list && _list.length) {
            //   debugger;
            //   this.authorizationService.setTransactionListToLocalDB(_list);
            // }
          }

          const _transacao =
            this.authorizationService.getAppTransactionByUrl(pages);
          if (_transacao) {
            this.authorizationService.setCurrentTransaction(_transacao);
          }
        } else {

          //ainda não tá logado nessa sessão
          if (pages.includes(environment.API_AREA_CLIENTE.Methods.PATH_APROVE_REQUEST)) {

            // debugger;

            //o cliente ainda não tá logado nessa sessão
            // const _urlAprovacao = pages.includes(environment.API_AREA_CLIENTE.Methods.PATH_APROVE_REQUEST);
            
            //e tá vindo do link de aprovação
            // if(_urlAprovacao) {
              //grava só a request
              // debugger;
              // const _request_id = pages.split('/');
              // const _id = JSON.stringify(_request_id[_request_id.length - 1]);
              // this.authService.setRequestPendingToAprove(_request_id[_request_id.length - 1]);
            //}

            this.routes.navigateByUrl(environment.API_AUTH.Methods.CUSTOMER_AUTH_LOGIN);
          }

        }

        if (pages && pages.includes('/')) {
          this.pages = pages.split('/');
        } else {
          this.pages = [pages];
        }
      }
    });

    this.idleService.idleStatusObserver().subscribe((value) => {
      this.idleStatus = value;

      this.idleBGColor = this.idleService.getBGColor;
    });
  }

  getLink(page: string) {
    if (page && page.includes('/')) {
      const _pages = page.split('/');
      if (_pages.length > 1) {
        const link = `_pages[0]/_pages[1]`;
        return link;
      }
    }
    return null;
  }

  ngOnInit(): void {
    // initFlowbite();
    
    // console.log('BREADCRUMB - parm url:', this.currentUrl);

    if (this.currentUrl && this.currentUrl.includes('/')) {
      this.pages = this.currentUrl.split('/');
    } else {
      this.pages = [this.currentUrl];
    }
  }
}
