import {
  CommonModule,
  isPlatformBrowser,
  isPlatformServer,
} from '@angular/common';
import {
  Component,
  EventEmitter,
  HostListener,
  inject,
  Input,
  OnInit,
  Output,
  PLATFORM_ID,
} from '@angular/core';
import { MenuItem } from 'primeng/api/menuitem';
import { BadgeModule } from 'primeng/badge';
import { PanelMenuModule } from 'primeng/panelmenu';
import { Usuario } from 'src/app/interfaces/security';
import { ISideNavToggle } from 'src/app/models/SideNav.data.model';
import { AccountService } from 'src/app/services/account.service';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { UserService } from 'src/app/services/user.service';
import { fadeInOut, rotateButton } from '../animations/sidenav.animations';
import { Router } from '@angular/router';
import { Empresa } from 'src/app/models/Account.data.model';
import {
  ModuloConfiguradoSistemaEntity,
  ModuloEmpresaConfiguradoEntity,
  ModuloUsuarioConfiguradoEntity,
} from 'src/app/models/Authorization.data.model';
import { AjusteLabelMenuPipe } from '../../pipes/ajuste-label-menu.pipe';

@Component({
  selector: 'sidenav-alternativo',
  standalone: true,
  imports: [CommonModule, PanelMenuModule, BadgeModule, AjusteLabelMenuPipe],
  animations: [fadeInOut, rotateButton],
  templateUrl: './sidenav-alternativo.component.html',
  styleUrl: './sidenav-alternativo.component.css',
})
export class SidenavAlternativoComponent implements OnInit {
  @Output() onToggleSideNav: EventEmitter<ISideNavToggle> = new EventEmitter();

  items: MenuItem[] = [];

  collapsed = false;
  pinned = false;
  screenWidth = 0;

  private _currentAccount?: Empresa;
  private _currentUser?: Usuario;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (this.isBrowser) {
      this.screenWidth = window.innerWidth;

      if (this.screenWidth <= 768) {
        this.collapsed = false;
        this.pinned = false;
        this.onToggleSideNav.emit({
          collapsed: this.collapsed,
          screenWidth: this.screenWidth,
          mobile: true,
        });
      }
      // console.log('[ SideNav ] screen:', this.screenWidth);
    }
  }

  platformId = inject(PLATFORM_ID);
  get isBrowser() {
    return isPlatformBrowser(this.platformId);
  }
  get isServer() {
    return isPlatformServer(this.platformId);
  }

  constructor(
    private readonly authorizationService: AuthorizationService,
    private readonly accountService: AccountService,
    private readonly userService: UserService,
    private readonly routes: Router
  ) {}

  ngOnInit(): void {
    // debugger;

    this._currentAccount = this.accountService.currentEmpresaLogada;
    this._currentUser = this.userService.currentUser;

    if (this.isBrowser) {
      this.screenWidth = window.innerWidth;

      this.onToggleSideNav.emit({
        collapsed: this.collapsed,
        screenWidth: this.screenWidth,
        mobile: this.screenWidth <= 768,
      });
    }

    this.authorizationService
      .menuDinamicoObserver()
      .subscribe(async (value) => {
        if (value) this.items = value;
      });

    // this.montaMenu();
    // this.items = [
    //   {
    //     label: 'Files',
    //     icon: 'pi pi-file',
    //     items: [
    //       {
    //         label: 'Documents',
    //         icon: 'pi pi-file',
    //         items: [
    //           {
    //             label: 'Invoices',
    //             icon: 'pi pi-file-pdf',
    //             items: [
    //               {
    //                 label: 'Pending',
    //                 icon: 'pi pi-stop',
    //               },
    //               {
    //                 label: 'Paid',
    //                 icon: 'pi pi-check-circle',
    //               },
    //             ],
    //           },
    //           {
    //             label: 'Clients',
    //             icon: 'pi pi-users',
    //           },
    //         ],
    //       },
    //       {
    //         label: 'Images',
    //         icon: 'pi pi-image',
    //         items: [
    //           {
    //             label: 'Logos',
    //             icon: 'pi pi-image',
    //           },
    //         ],
    //       },
    //     ],
    //   },
    //   {
    //     label: 'Cloud',
    //     icon: 'pi pi-cloud',
    //     items: [
    //       {
    //         label: 'Upload',
    //         icon: 'pi pi-cloud-upload',
    //       },
    //       {
    //         label: 'Download',
    //         icon: 'pi pi-cloud-download',
    //       },
    //       {
    //         label: 'Sync',
    //         icon: 'pi pi-refresh',
    //       },
    //     ],
    //   },
    //   {
    //     label: 'Devices',
    //     icon: 'pi pi-desktop',
    //     items: [
    //       {
    //         label: 'Phone',
    //         icon: 'pi pi-mobile',
    //       },
    //       {
    //         label: 'Desktop',
    //         icon: 'pi pi-desktop',
    //       },
    //       {
    //         label: 'Tablet',
    //         icon: 'pi pi-tablet',
    //       },
    //     ],
    //   },
    // ];
  }

  toggleCollapsed() {
    if (this.pinned) return;

    this.collapsed = !this.collapsed;

    this.recolheAll();

    this.onToggleSideNav.emit({
      collapsed: this.collapsed,
      screenWidth: this.screenWidth,
      mobile: this.screenWidth <= 768,
    });
  }

  pinSideNav() {
    this.pinned = !this.pinned;
  }

  /**
   * Monta o menu com os módulos e transações permitidas ao usuário.
   *
   * As permissões serão definidas em 3 níveis: Usuário, Empresa e Sistema
   *
   * Caso o usuário tenha permissão particular definida, o menu será montado de acordo
   * com as transações habilitadas para o usuário.
   *
   * Caso o usuário não tenha permissão particular definida, o menu será montado de acordo
   * com as transações habilitadas para a empresa do usuário de acordo com o perfil desse
   * usuário.
   *
   * Caso o usuário não tenha permissão particular definida e nem para a empresa do usuário,
   * o menu será montado de acordo com as transações habilitadas para o sistema de acordo com
   * o perfil desse usuário.
   *
   * @returns
   */
  async montaMenu() {
    // debugger;

    this.items = [];

    if (this._currentAccount?.empresa_id && this._currentUser?.userid) {
      const { empresa_id } = this._currentAccount;
      const { userid } = this._currentUser;

      const _userProfile = this.authorizationService.currentUserProfile;
      console.log(
        'SideNavAlternativo->processaEmpresaSelecionada->_userProfile',
        _userProfile
      );

      //nível USUÁRIO: módulos e transações que o usuário possui acesso
      const _modulosAcessoUsuario =
        await this.authorizationService.getModulosConfiguradosUsuario(
          empresa_id,
          userid
        );
      console.log(
        'SideNavAlternativo->processaEmpresaSelecionada->_modulosAcesso (USUÁRIO):',
        _modulosAcessoUsuario
      );

      if (_modulosAcessoUsuario && _modulosAcessoUsuario.length) {
        // permissionamento relacionado ao usuário: Módulos e Transações por módulo

        this.items = await this.montaMenuOpcoes({
          modulosUsuario: _modulosAcessoUsuario,
        });
      } else {
        //
        // Segundo nível de permissionamento
        //
        if (_userProfile) {
          //permissionamento relacionado ao perfil

          if (_userProfile && _userProfile !== 'ADMIN') {
            //1. buscar no perfil a nível de empresa. se não encontrar...

            //nível EMPRESA: módulos e transações que o perfil do usuário possui acesso definido pela
            //               empresa
            const _modulosAcessoPerfilEmpresa =
              await this.authorizationService.getModulosConfiguradosEmpresa({
                empresa_id,
                perfil: _userProfile,
              });
            console.log(
              'SideNavAlternativo->processaEmpresaSelecionada->_modulosAcesso (Perfil do USUÁRIO na EMPRESA):',
              _modulosAcessoPerfilEmpresa
            );

            if (
              _modulosAcessoPerfilEmpresa &&
              _modulosAcessoPerfilEmpresa.length
            ) {
              this.items = await this.montaMenuOpcoes({
                modulosEmpresa: _modulosAcessoPerfilEmpresa,
              });

              return;
            }

            //2. buscar no perfil a nível de sistema
            const _modulosAcessoPerfilSistema =
              await this.authorizationService.getModulosConfiguradosSistema(
                _userProfile
              );
            console.log(
              'SideNavAlternativo->processaEmpresaSelecionada->_modulosAcesso (Perfil do USUÁRIO no SISTEMA):',
              _modulosAcessoPerfilSistema
            );

            if (
              _modulosAcessoPerfilEmpresa &&
              _modulosAcessoPerfilEmpresa.length
            ) {
              this.items = await this.montaMenuOpcoes({
                modulosSistema: _modulosAcessoPerfilSistema,
              });
            }

            return;
          }

          //monta o menu do Admin

          const _menuAdmin =
            await this.authorizationService.getMountedMenuList();

          if (_menuAdmin && _menuAdmin.length > 0) {
            this.items = _menuAdmin.map((item) => {
              return {
                label: item.label,
                icon: item.icon,
                items: item.items?.map((subItem) => {
                  return {
                    label: subItem.label,
                    icon: subItem.icon,
                    route: subItem.routerLink,
                    visible: subItem.is_visible,
                    items: subItem.items?.map((subSubItem) => {
                      return {
                        label: subSubItem.label,
                        icon: subSubItem.icon,
                        route: subItem.routerLink,
                        visible: subSubItem.is_visible,
                      };
                    }),
                  };
                }),
              };
            });
          }
        }
      }
    }
  }

  private async montaMenuOpcoes(parms: {
    modulosUsuario?: ModuloUsuarioConfiguradoEntity[];
    modulosEmpresa?: ModuloEmpresaConfiguradoEntity[];
    modulosSistema?: ModuloConfiguradoSistemaEntity[];
  }) {
    const { modulosUsuario, modulosEmpresa, modulosSistema } = parms;

    const transacoesFullList =
      await this.authorizationService.getTransacoesList();

    const _menuItensResult: MenuItem[] = [];

    //
    // NÍVEL: USUÁRIO
    //
    if (modulosUsuario && modulosUsuario.length) {
      //monta o menu de acordo com as transações habilitadas para o usuário
      // para cada módulo, cria uma lista com as transações para gera a lista final
      for (const modulo of modulosUsuario) {
        if (modulo) {
          const { transaction_modulo_fk, is_habilitado, Permissoes } = modulo;

          if (
            transaction_modulo_fk &&
            is_habilitado &&
            Permissoes &&
            Permissoes.length
          ) {
            //percorre as transações...
            const _itensModulo: MenuItem[] = [];

            for (const transacao of Permissoes) {
              if (transacao) {
                const { transacao_fk, is_habilitado } = transacao;

                if (transacao_fk) {
                  const _dados = transacoesFullList.find(
                    (item) => item.transaction_id === transacao_fk
                  );

                  if (_dados) {
                    _itensModulo.push({
                      label: _dados.label,
                      icon: _dados.icone,
                      routerLink: _dados.routerLink,
                      visible: is_habilitado,
                    });
                  }
                }
              }
            }

            //o módulo
            const _dadosModulo = transacoesFullList.find(
              (item) => item.transaction_id === transaction_modulo_fk
            );

            if (_dadosModulo) {
              _menuItensResult.push({
                label: _dadosModulo.label,
                icon: _dadosModulo.icone,
                items: _itensModulo,
              });
            }
          }
        }
      }
    } else if (modulosEmpresa && modulosEmpresa.length) {
      //
      // NÍVEL: EMPRESA
      //monta o menu de acordo com as transações habilitadas para a empresa do usuário

      for (const modulo of modulosEmpresa) {
        if (modulo) {
          const { transaction_modulo_fk, is_habilitado, Permissoes } = modulo;

          if (
            transaction_modulo_fk &&
            is_habilitado &&
            Permissoes &&
            Permissoes.length
          ) {
            //percorre as transações...
            const _itensModulo: MenuItem[] = [];

            for (const transacao of Permissoes) {
              if (transacao) {
                const { transacao_fk, is_habilitado } = transacao;

                if (transacao_fk) {
                  const _dados = transacoesFullList.find(
                    (item) => item.transaction_id === transacao_fk
                  );

                  if (_dados) {
                    _itensModulo.push({
                      label: _dados.label,
                      icon: _dados.icone,
                      routerLink: _dados.routerLink,
                      visible: is_habilitado,
                    });
                  }
                }
              }
            }

            //o módulo
            const _dadosModulo = transacoesFullList.find(
              (item) => item.transaction_id === transaction_modulo_fk
            );

            if (_dadosModulo) {
              _menuItensResult.push({
                label: _dadosModulo.label,
                icon: _dadosModulo.icone,
                items: _itensModulo,
              });
            }
          }
        }
      }
    } else if (modulosSistema && modulosSistema.length) {
      //
      // NÍVEL: SISTEMA
      //
      //monta o menu de acordo com as transações habilitadas para o sistema

      for (const modulo of modulosSistema) {
        if (modulo) {
          const { transaction_modulo_fk, is_habilitado, Permissoes } = modulo;

          if (
            transaction_modulo_fk &&
            is_habilitado &&
            Permissoes &&
            Permissoes.length
          ) {
            //percorre as transações...
            const _itensModulo: MenuItem[] = [];

            for (const transacao of Permissoes) {
              if (transacao) {
                const { transacao_fk, is_habilitado } = transacao;

                if (transacao_fk) {
                  const _dados = transacoesFullList.find(
                    (item) => item.transaction_id === transacao_fk
                  );

                  if (_dados) {
                    _itensModulo.push({
                      label: _dados.label,
                      icon: _dados.icone,
                      routerLink: _dados.routerLink,
                      visible: is_habilitado,
                    });
                  }
                }
              }
            }

            //o módulo
            const _dadosModulo = transacoesFullList.find(
              (item) => item.transaction_id === transaction_modulo_fk
            );

            if (_dadosModulo) {
              _menuItensResult.push({
                label: _dadosModulo.label,
                icon: _dadosModulo.icone,
                items: _itensModulo,
              });
            }
          }
        }
      }
    }

    return _menuItensResult;
  }

  get currentModalidade() {
    return this.accountService.getAccountModalityFomLocalHistory()!;
  }

  getActiveClass(data: MenuItem) {
    //localiza dentro da lista de items se a opção selecionada é pertencente

    if (data.items && data.items.length && data.label) {
      // debugger;

      const _url = decodeURI(this.routes.url);
      const _modulo = _url.split('/')[1];
      const _transacao = _url.split('/')[2];

      // console.log('[ SideNavAlternativo ] getActiveClass ->', _transacao, data.items);

      const _itens = data.items.map((item) => {
        return {
          label: item.label,
          icon: item.icon,
          route: item['route'],
          items: item.items?.map((subItem) => {
            return {
              label: subItem.label,
              icon: subItem.icon,
              route: item['route'],
            };
          }),
        };
      });

      const _existe = _itens.find(
        (item) => item.route && item.route.includes(_transacao)
      );

      if (_existe) {
        // console.log('[ SideNavAlternativo ] getActiveClass ->', _existe);
        return `bg-topvix-secondary text-white`;
      }
    }
    return 'bg-transparent';
  }

  // CONTROLE DE EXPANSÃO E CONTRAÇÃO DO MENU
  toggleAll() {
    const expanded = !this.areAllItemsExpanded();
    this.items = this.toggleAllRecursive(this.items, expanded);
  }

  recolheAll() {
    // const expanded = !this.areAllItemsExpanded();
    this.items = this.toggleAllRecursive(this.items, false);
  }

  private toggleAllRecursive(items: MenuItem[], expanded: boolean): MenuItem[] {
    return items.map((menuItem) => {
      menuItem.expanded = expanded;
      if (menuItem.items) {
        menuItem.items = this.toggleAllRecursive(menuItem.items, expanded);
      }
      return menuItem;
    });
  }

  private areAllItemsExpanded(): boolean {
    return this.items.every((menuItem) => menuItem.expanded);
  }
}
