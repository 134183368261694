import { Component, effect, inject, OnInit, PLATFORM_ID } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { Router } from '@angular/router';
import { ReactiveFormsModule, FormBuilder, Validators } from '@angular/forms';
import { TextFieldComponent } from '../../components/text-field/text-field.component';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../services/user.service';
import { LoadingService } from '../../services/loading.service';
import {
  CommonModule,
  isPlatformBrowser,
  isPlatformServer,
} from '@angular/common';
import { accessTokenDataModel } from '../../models/Token.data.model';
import { ButtonTemplateComponent } from '../../components/button-template/button-template.component';
import { AccountService } from '../../services/account.service';
import { AuthorizationService } from '../../services/authorization.service';
import { AccountSelectionComponent } from '../../modules/authentication/account-selection/account-selection.component';
import { Empresa } from '../../models/Account.data.model';
import { secUserAccount } from '../../interfaces/security';
import { IdleService } from '../../services/idle.service';
import { WebsocketService } from '../../services/websocket.service';
import { UtilitiesService } from '../../services/utilities.service';

@Component({
  selector: 'app-login',
  standalone: true,
  templateUrl: './login.component.html',
  styleUrl: './login.component.css',
  imports: [
    ReactiveFormsModule,
    TextFieldComponent,
    CommonModule,
    ButtonTemplateComponent,
    AccountSelectionComponent,
  ],
  providers: [],
})
export class LoginComponent implements OnInit {
  token = new accessTokenDataModel();
  routes = inject(Router);

  isSubmitted = false;
  currentLogo: string = '../../../assets/images/logo-sistema.png';

  showAccountSelection = false;
  accountList: Empresa[] = [];

  loginData: any;
  replyData: any;

  constructor(
    private readonly authService: AuthenticationService,
    private readonly userService: UserService,
    private readonly accountService: AccountService,
    private readonly fb: FormBuilder,
    private readonly toastr: ToastrService,
    private readonly loadingService: LoadingService,
    private readonly authorizationService: AuthorizationService,
    private readonly idleService: IdleService,
    private readonly wsService: WebsocketService,
    private readonly utility: UtilitiesService
  ) {
    // this.formLogin.valueChanges.subscribe((val)=>console.log(this.formLogin));
    effect(() => {});
  }

  platformId = inject(PLATFORM_ID);

  get isBrowser() {
    return isPlatformBrowser(this.platformId);
  }
  get isServer() {
    return isPlatformServer(this.platformId);
  }

  ngOnInit(): void {
    this.loadingService.isLoading.subscribe(
      (value) => (this.isSubmitted = value)
    );

    if (this.isBrowser) {
      this.wsService.wsLoginDataObserver().subscribe((value) => {
        this.loginData = value;
        console.log('WS_Socket [ Login ] loginData:', this.loginData);
      });

      this.wsService.wsReplyDataObserver().subscribe((value) => {
        this.replyData = value;
        console.log('WS_Socket [ Login ] replyData:', this.replyData);
      });

      this.authService.userLogoff();
      this.wsService.performWSUserLogoff();

      this.formLogin.reset();

      const _logo = this.accountService.getAccountLogoFromLocalHistory();
      if (_logo) this.currentLogo = _logo;
    }
  }

  formLogin = this.fb.group({
    email_field: ['', [Validators.required, Validators.email]],
    pass_field: ['', Validators.required],
  });

  get fc() {
    return this.formLogin.controls;
  }

  get fv() {
    return this.formLogin.value;
  }

  async submitLogin() {
    // console.log('submit:', this.formLogin);

    if (this.formLogin.invalid) return;

    await this.getLoginToken();
  }

  goHome() {
    // debugger;
    const _last_page = this.authorizationService.lastPage;
    this.routes.navigateByUrl(_last_page?.routerLink || 'Dashboard/Dashboard do Sistema');
  }

  redirectTo(page: string) {
    this.routes.navigateByUrl(page);
  }

  onCancelSelection() {
    console.log('[ Login ] cancelar selecionar empresa');
    this.authService.userLogoff();
    this.wsService.performWSUserLogoff();

    this.routes.navigateByUrl('/');
    this.showAccountSelection = false;
  }

  onAccountSelection(account: Empresa) {
    this.processaEmpresaSelecionada(account);
  }

  async processaEmpresaSelecionada(account: Empresa) {
    // debugger;
    console.log('[ Login ] empresa selecionada:', account);

    if (account && account.empresa_id && account.modalidade_cashback) {
      let podeSeguir = false;

      // debugger;

      if (this.userService.currentUser && this.userService.currentUser.userid) {
        //recupera as permissões desse usuário

        const _userProfile: secUserAccount = {
          empresa_fk: account.empresa_id,
          status: this.userService.currentUser.status,
          user_account_role: this.userService.currentUser.user_role,
          Empresa: account,
          User: this.userService.currentUser,
        };

        const _perfil_na_empresa =
          account.UsuarioEmpresa?.find(
            (item) => item.empresa_fk === account.empresa_id
          )?.user_account_role || this.userService.currentUser.user_role;

        this.accountService.setCurrentEmpresa(account);
        this.accountService.setAccountToLocalHistory(account);
        this.accountService.setAccountModalityToLocalHistory(
          account.modalidade_cashback
        );
        this.authorizationService.setCurrentUserProfile(_perfil_na_empresa);
        this.authorizationService.setUserProfileToLocalHistory(
          _perfil_na_empresa
        );

        this.showAccountSelection = false;

        podeSeguir = true;
      }

      if (podeSeguir) {
        const { userid, user_role, username } = this.userService.currentUser;

        if (userid && user_role) {
          if (user_role === 'ADMIN') {
            this.toastr.success(
              `Olá ${username}, você é um Admin e possui acesso completo e total no sistema!`
            );
            podeSeguir = true;
          }

          if (podeSeguir) {
            this.authorizationService.setAuthorizationStatus(true);
            this.idleService.setIdleStatus('IN ACTIVITY');
            this.idleService.setIdleCheckingActivation(true);

            await this.authService.registerUserAccessLog(
              'LOGIN',
              userid,
              user_role === 'ADMIN' ? undefined : account.empresa_id!,
              'LOGIN_PAGE'
            );

            this.authService.setSessionStatus('ativa');

            console.log(
              'WS_Socket - enviar mensagem após login com sucesso...'
            );

            if (this.wsService.connect()) {
              const _geoData = this.authService.getGeolocationData();

              this.wsService.performUserLogin({
                action: 'login',
                userid,
                username,
                user_role,
                empresa_logada: account.empresa_id,
                ip_address: _geoData?.ip || undefined,
                token: this.authService.getTokenFomLocalHistory().accessToken,
              });
            }

            this.authService.setCurrentLoginStatusObserver('LOGIN');

            console.log(
              'WS_Socket [ Login ] mensagem do servidor:',
              this.loginData
            );
            console.log(
              'WS_Socket [ Reply ] mensagem do servidor:',
              this.replyData
            );

            this.authorizationService.montaMenuDinamico(
              account.empresa_id,
              userid
            );

            this.goHome();
          }
        }
      }
    }
  }

  async getLoginToken() {
    if (this.fv.email_field && this.fv.pass_field) {
      let _sucesso = true;

      // debugger;

      const _userData = await this.authService
        .userLogin(this.fv.email_field, this.fv.pass_field)
        .catch((reason) => {
          this.toastr.error(reason);

          this.authService.userLogoff();
          this.wsService.performWSUserLogoff();

          _sucesso = false;
        });

      if (_sucesso && _userData) {
        // tratamento do acessos e permissões do usuário
        //1. lista corrida e;
        //2. lista com menu/sub-menu
        console.log('WS_Service - entrar no connect após login...');
        // this.wsService.connect();

        this.authorizationService.setCurrentLoginAreaToHistory('empresa');

        // o usuário deverá ter acesso a, pelo menos, uma empresa
        if (
          _userData &&
          _userData.EmpresasAcesso &&
          _userData.EmpresasAcesso.length
        ) {
          //só tem acesso a uma empresa
          if (
            _userData.EmpresasAcesso.length === 1 &&
            _userData.EmpresasAcesso[0] &&
            _userData.EmpresasAcesso[0].Empresa
          ) {
            await this.processaEmpresaSelecionada(
              _userData.EmpresasAcesso[0].Empresa
            );

            this.authService.setCurrentLoginStatusObserver('LOGIN');
            return;
          }

          //se tiver a mais de uma empresa... seleciona a que quer acessar
          const _empresas = _userData.EmpresasAcesso;
          this.accountList = [];

          _empresas.map((value) => {
            if (value.Empresa) this.accountList.push(value.Empresa);
          });
          this.showAccountSelection = true;
        }
      }
    }
  }
}
