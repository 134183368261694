import { inject, Pipe, PipeTransform } from '@angular/core';
import { AccountService } from '../services/account.service';

@Pipe({
  name: 'empresa',
  standalone: true
})
export class EmpresaPipe implements PipeTransform {

  accountService = inject(AccountService);

  async transform(empresa_id: number|undefined, campo: 'nome_fantasia'|'logo') {

    if(empresa_id && this.accountService.currentEmpresaLogada) {
      const { nome_fantasia, razao_social, imagem } = this.accountService.currentEmpresaLogada;

      if(campo === 'nome_fantasia' && (nome_fantasia || razao_social)) {
        return nome_fantasia || razao_social || '';
      } 
      
      if(campo === 'logo' && imagem) {
        return imagem || '';
      }

      const empresa = await this.accountService.getAccountBasicDataById(empresa_id);
      if(empresa) {
        if(campo === 'nome_fantasia') {
          return empresa.nome_fantasia || empresa.razao_social || '';
        } else if(campo === 'logo') {
          return empresa.imagem || '';
        }
      }
    }

    return empresa_id?.toString();
  }

}
