import { Pipe, PipeTransform } from '@angular/core';
import { emodalidade_cashback } from '../enums/Enums';
import { AccountService } from '../services/account.service';

@Pipe({
  name: 'ajusteLabelMenu',
  standalone: true,
})
export class AjusteLabelMenuPipe implements PipeTransform {

  constructor(private readonly accountService: AccountService) {}

  _opcoes = [
    'Validade dos pontos',
    'Regra de pontuação',
    'Pontuação por categoria',
  ];

  modalidade = this.accountService.getAccountModalityFomLocalHistory();


  // ajuste do label de algumas opções de menu caso a modalidade de cashbvack for VALOR
  transform(value?: string) {    
    
    if (value) {

      // console.log('value:', value);

      if (this.modalidade && this.modalidade == 'VALOR') {
        if (value === this._opcoes[0]) return 'Validade do cashback';
        if (value === this._opcoes[1]) return 'Regra de cashback';
        if (value === this._opcoes[2]) return 'Cashback por categoria';
      }

      return value;
    }
    return null;
  }
}
