<ng-container *ngIf="isBrowser">
  <p-dialog *ngIf="isBrowser" 
    [(visible)]="showDialog"
    [modal]="true"
    autoZIndex="true"
    maximizable="true"
    [responsive]="true"
    [style.width]="maxWidth"
    (onHide)="sendBack_NO_CroppedImage()"
  >
    <ng-template pTemplate="header">
      <div
        class="w-full flex justify-start items-center gap-2 px-4 py-2 mx-auto"
      >
        <span class="icon-[mdi--image-size-select-large] w-10 h-10"></span>
        <span class="text-xl font-bold white-space-nowrap">
          Recortar imagem na proporção ideal
        </span>
      </div>
    </ng-template>

    <image-cropper
      [imageFile]="data.image"
      [imageChangedEvent]="imageChangedEvent"
      [maintainAspectRatio]="true"
      [aspectRatio]="ratio()"
      [imageQuality]="100"
      format="png"
      (imageCropped)="imageCropped($event)"
      (imageLoaded)="imageLoaded($event)"
      (cropperReady)="cropperReady()"
      (loadImageFailed)="loadImageFailed()"
    ></image-cropper>

    <div
      class="w-full flex justify-end items-center gap-5 px-4 py-1 bg-gray-200 border-t"
    >
      <button-template
        label="Fechar"
        icon="icon-[mdi--close] text-xl"
        [buttonStyle]="'outline'"
        (onClick)="sendBack_NO_CroppedImage()"
      ></button-template>
      <button-template
        label="Confirmar"
        icon="icon-[mdi--content-save-outline] text-xl"
        [buttonStyle]="'filled'"
        (onClick)="sendBackCroppedImage()"
      ></button-template>
    </div>
  </p-dialog>
</ng-container>
