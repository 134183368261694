<div
    class="h-full flex flex-col justify-start items-start gap-1 transition-all duration-150 ease-linear overflow-hidden z-10 px-0.5 py-2 mr-1 shadow-xl"
    (mouseenter)="toggleCollapsed()"
    (mouseleave)="toggleCollapsed()"
    [ngClass]="collapsed ? 'w-64' : 'w-16'">

    <!-- ALFINETE FIXAR MENU -->
    <div class="h-16 flex align-middle justify-between gap-1 w-full px-1 my-auto">

        <button
            @fadeInOut
            class="text-center w-12 min-w-12 p-1 font-medium cursor-pointer border-0">
            
            <span
            class="text-2xl"
            [ngClass]="
                collapsed
                ? 'icon-[carbon--right-panel-open]'
                : 'icon-[carbon--right-panel-close]'
            "
            ></span>
        </button>
        
        <div *ngIf="collapsed" class="my-auto text-2xl font-bold text-topvix-button">
            Topvix
        </div>

        <button @rotate (click)="pinSideNav()" *ngIf="collapsed">
            <span *ngIf="!pinned" class="icon-[carbon--pin] text-2xl"></span>
            <span
            *ngIf="pinned"
            class="icon-[carbon--pin-filled] text-2xl animate-pulse text-red-500 rounded-full p-2 backdrop-blur-2xl"
            ></span>
        </button>
    </div>


    <!-- MÓDULOS E MENUS -->
    <div class="size-full card items-start h-full min-h-[35rem] overflow-y-auto">
        <p-panelMenu [model]="items" styleClass="w-full px-1">
            <ng-template pTemplate="item" let-item>

                <!-- AS TRANSAÇÕES DO MENU -->
                <ng-container *ngIf="item.route; else urlRef">
                    
                    <a [routerLink]="item.route" 
                        routerLinkActive="bg-topvix-secondary text-white"
                        [routerLinkActiveOptions]="{ exact: true }"
                        class="flex gap-2 items-center cursor-pointer px-3 py-2 w-full ps-4 rounded-md text-sm text-topvix-secondary hover:text-white hover:bg-topvix-button">
                        <span class="{{item.icon}} w-[1.5rem] h-[1.5rem]"></span>
                        <span class="ml-2 font-medium">{{ item.label | ajusteLabelMenu }}</span>
                    </a>
                </ng-container>

                <ng-template #urlRef>

                    <a *ngIf="item.url; else noLink" [href]="item.url" class="flex items-center 
                        cursor-pointer text-topvix-button px-3 py-2">
                        <span class="{{item.icon}} h-6 w-6"></span>
                        <span class="ml-2">{{ item.label }}</span>
                    </a>

                    <!-- MENU DO MÓDULO -->
                    <ng-template #noLink>
                        <span 
                            [ngClass]="getActiveClass(item)"
                            class="w-full flex justify-center items-center cursor-pointer text-topvix-button text-md px-4 py-2 rounded-lg hover:text-white hover:bg-topvix-button">
                            <span class="{{item.icon}} min-w-8 min-h-8"></span>
                            <span *ngIf="collapsed" class="ml-3">{{ item.label }}</span>
                            <i *ngIf="item.items && !item.expanded" class="icon-[carbon--chevron-right] w-6 h-6 ml-auto"></i>
                            <i *ngIf="item.items && item.expanded" class="icon-[carbon--chevron-down] w-6 h-6 ml-auto"></i>
                        </span>
                    </ng-template>

                </ng-template>
            </ng-template>
        </p-panelMenu>
    </div>

    <!-- BOTÃO SAIR -->
    <div class="w-full flex justify-center items-center mt-auto px-2 py-1 mb-1 rounded-md shadow text-red-500 hover:bg-red-500 hover:text-red-50 duration-150 transition-all">

        <a routerLink="login" class="w-full flex justify-center items-center gap-1">
            <span class="icon-[mdi--exit-run] w-8 h-8"></span>

            <span *ngIf="collapsed" class="w-full font-bold">Sair</span>
        </a>       
    </div>

</div>
