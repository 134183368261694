import { Pipe, PipeTransform } from '@angular/core';
import { SpecialDataService } from '../services/special-data.service';
import { AccountService } from '../services/account.service';

@Pipe({
  name: 'pageAlias',
  standalone: true
})
export class PageAliasPipe implements PipeTransform {

  constructor(
    private readonly specialData: SpecialDataService,
    private readonly accountService: AccountService
  ) {}
  
  _opcoes = [
    'Validade dos pontos',
    'Validade Pontos',
    'Regra de pontuação',
    'Regra Pontuação',
    'Pontuação por categoria',
    'Pontuação Categoria',
  ];

  modalidade = this.accountService.getAccountModalityFomLocalHistory();

  transform(value: any): string|null {

    const _alias = this.specialData.rotasAlias();
    let _value = value;

    if(!value) return null;

    if(_alias) {
      _value = _alias[value];
      if(!_value) {
        _value = value;
      }
    }

    // console.log('value:', _value);

    if (_value) {
      if (this.modalidade && this.modalidade == 'VALOR') {
        if (_value === this._opcoes[0]) return 'Validade do cashback';
        if (_value === this._opcoes[1]) return 'Validade do Cashback';
        if (_value === this._opcoes[2]) return 'Regra de cashback';
        if (_value === this._opcoes[3]) return 'Regra de Cashback';
        if (_value === this._opcoes[4]) return 'Cashback por categoria';
        if (_value === this._opcoes[5]) return 'Cashback Categoria';
      }
    }

    return _value;
  }

}
