import { Component, Input } from '@angular/core';
import { Idle } from '@ng-idle/core';
import { IdleModules } from '../../idle.module';
import { CommonModule } from '@angular/common';


@Component({
  selector: 'app-home',
  standalone: true,
  imports: [IdleModules, CommonModule],
  providers: [Idle],
  templateUrl: './home.component.html',
  styleUrl: './home.component.css',
})
export class HomeComponent {
  @Input() collapsed = false;
  @Input() screenWidth = 0;

  title = 'New Topvix';

  constructor() {  }


  getBodyClass() {
    const _class = "h-full ml-1 z-0 relative top-0 transition-all duration-150 ease-linear";

    if (this.collapsed)
      return `w-[calc(100vw - 16.5625rem)] ${_class}`;

    return `w-[calc(100vw - 15rem)] ${_class}`;
  }

}
