import { CommonModule, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Component, EventEmitter, inject, Input, Output, PLATFORM_ID, signal } from '@angular/core';

import { DialogModule } from 'primeng/dialog';

import {
  ImageCropperComponent,
  ImageCroppedEvent,
  LoadedImage,  
} from 'ngx-image-cropper';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ButtonTemplateComponent } from "../../../components/button-template/button-template.component";
import { CroppedDialogData, CroppedDialogResult } from '../../../enums/Enums';
import { UtilitiesService } from '../../../services/utilities.service';

@Component({
  selector: 'image-cropper-page',
  standalone: true,
  imports: [
    CommonModule,
    ImageCropperComponent,
    DialogModule,
    ButtonTemplateComponent
],
  templateUrl: './image-cropper-page.component.html',
  styleUrl: './image-cropper-page.component.css',
})
export class ImageCropperPageComponent {

  maxImageWidth = signal(0);
  @Input() set maxWidth(value: number) {
    this.maxImageWidth.set(value);
  }
  
  maxImageHeight = signal(0);
  @Input() set maxHeight(value: number) {
    this.maxImageHeight.set(value);
  }

  ratio = signal(0);
  @Input() set imageRatio(value: number) {
    this.ratio.set(value);
  }

  @Input() data!: CroppedDialogData;

  @Input() imageBase64: string = '';
  @Input() set show(value: boolean) {
    this.showDialog = value;
  }
  @Output() onImageCropped: EventEmitter<CroppedDialogResult> = new EventEmitter<CroppedDialogResult>();
  
  result = signal<CroppedDialogResult | undefined>(undefined);


  showDialog = false;
  imageChangedEvent: Event | null = null;
  croppedImage: SafeUrl = '';

  imageBlob?: Blob;

  platformId = inject(PLATFORM_ID);
  get isBrowser() {
    return isPlatformBrowser(this.platformId);
  }
  get isServer() {
    return isPlatformServer(this.platformId);
  }
  
  constructor(
    private sanitizer: DomSanitizer,
    private readonly utility: UtilitiesService,
  ) {}

  fileChangeEvent(event: Event): void {
    this.imageChangedEvent = event;
  }

  async imageCropped(event: ImageCroppedEvent) {
    const { blob, objectUrl, base64 } = event;
    // console.log('[ ImageCroppedEvent ] blob:', blob, ', objetUrl:', objectUrl, 'base64:', base64);

    if(blob && objectUrl) {
      const _b64 = await this.utility.convertBlobToBase64(blob);
      // console.log('[ ImageCroppedEvent ] blob -> base64 convertido:', _b64);

      this.result.set({
        blob: blob,
        imageUrl: objectUrl,
        base64: _b64,
        filename: this.data.filename,
      });
    }

    if(base64) {
      this.result.set({
        blob: undefined,
        imageUrl: undefined,
        base64: base64,
        filename: this.data.filename,
      });
    }

    // if (event && event.objectUrl) {
    //   this.croppedImage = this.sanitizer.bypassSecurityTrustUrl(
    //     event.objectUrl
    //   );
    // }

    // if(event && event.blob)
    //   this.imageBlob = event.blob;
    // event.blob can be used to upload the cropped image

  }
  imageLoaded(image: LoadedImage) {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  sendBackCroppedImage() {
    if(this.result()) {
      this.onImageCropped.emit(this.result());
    }
  }

  sendBack_NO_CroppedImage() {
    this.onImageCropped.emit(undefined);
    this.showDialog = false;
  }
}
