import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userRole',
  standalone: true
})
export class UserRolePipe implements PipeTransform {

  transform(key?: string): unknown {

    if(key) {
      switch(key) {
        case 'USER':
          return 'Usuário';
        case 'MANAGER':
          return 'Gerente';
        case 'ADMIN':
          return 'Administrador';
        case 'CUSTOMER':
          return 'Cliente';
      }
    }
    return 'Não identificado';
  }

}
