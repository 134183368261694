import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { ButtonTemplateComponent } from '../../../components/button-template/button-template.component';
import { Empresa } from '../../../models/Account.data.model';
import { TipComponent } from '../../../components/tip/tip.component';
import { AvatarModule } from 'primeng/avatar';

@Component({
  selector: 'account-selection',
  standalone: true,
  templateUrl: './account-selection.component.html',
  styleUrl: './account-selection.component.css',
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonTemplateComponent,
    TipComponent,
    AvatarModule,
  ],
})
export class AccountSelectionComponent implements OnInit, OnDestroy, OnChanges {
  @Input() accountList: Empresa[] = [];
  @Output('onConfirm') confirmEmitter = new EventEmitter<Empresa>();
  @Output('onCancel') cancelEmitter = new EventEmitter();

  selectedAccount?: Empresa;
  currentLogo: string = '../../../assets/images/logo-sistema.png';

  constructor(private readonly builder: FormBuilder) {}

  formAccountSelection = this.builder.group({
    empresa_id: [0, [Validators.required, Validators.min(1)]],
  });

  ngOnInit(): void {
    this.formAccountSelection.controls.empresa_id.setValue(0);
  }
  ngOnDestroy(): void {}
  ngOnChanges(changes: SimpleChanges): void {}

  selection(account: Empresa) {
    this.selectedAccount = account;
    this.confirmEmitter.emit(account);
  }

  confirm() {
    if (this.selectedAccount) this.confirmEmitter.emit(this.selectedAccount);
  }

  cancel() {
    this.cancelEmitter.emit();
  }
}
